import { CartBaseController } from "./cart_base_controller";

export default class extends CartBaseController {
  static values = {
    apply: String,
    applied: String,
  };

  async apply(event) {
    event.preventDefault();
    event.stopPropagation();

    // XXX: Esto es porque el formulario está dentro de otro y no hereda
    // los inputs.
    const submitTarget = event.target.elements.submit;
    const couponCodeTarget = event.target.elements.coupon_code;
    const coupon_code = couponCodeTarget.value.trim();
    const orderToken = this.token;

    if (coupon_code.length === 0) return;

    this.disableForm(event.target, true);

    const response = await window.spree.cart.applyCouponCode(
      { orderToken },
      { coupon_code }
    );

    if (response.isFail()) {
      this.disableForm(event.target, false);

      couponCodeTarget.setCustomValidity(response.fail().summary);
      event.target.reportValidity();
      couponCodeTarget.setCustomValidity("");

      return;
    } else {
      const cartPayment = document.querySelector('[data-controller="cart-payment"]');
      const { subtotal, total, ship_total, item_total, adjustment_total } = response.success().data.attributes;

      cartPayment.dataset.cartPaymentSubtotalValue = subtotal;
      cartPayment.dataset.cartPaymentItemTotalValue = item_total;
      cartPayment.dataset.cartPaymentShipTotalValue = ship_total;
      cartPayment.dataset.cartPaymentAdjustmentTotalValue = adjustment_total;
      cartPayment.dataset.cartPaymentTotalValue = total;
    }

    this.disableForm(event.target, false);

    const buttonContent = submitTarget.querySelector(".hide-when-disabled");
    if (!buttonContent) return;

    buttonContent.innerHTML = this.appliedValue;
    this.timeout = setTimeout(() => buttonContent.innerHTML = this.applyValue, 2000);
  }

  disconnect () {
    clearTimeout(this.timeout);
  }
}
