import { CartBaseController } from "./cart_base_controller";

export default class extends CartBaseController {
  static targets = ["paymentMethods", "paymentMethodTemplate", "subtotal", "total", "currency", "adjustmentTotal", "itemTotal", "shipTotal", "adjustment"];
  static values = {
    nextStep: String,
    subtotal: String,
    itemTotal: String,
    adjustmentTotal: String,
    shipTotal: String,
    total: String,
    currency: String,
  };

  currencyValueChanged (currency) {
    for (const currencyTarget of this.currencyTargets) {
      currencyTarget.innerText = currency;
    }
  }

  totalValueChanged (totalValue) {
    const total = this.localizeNumber(totalValue);
    const subtotal = this.localizeNumber(this.subtotalValue);
    const itemTotal = this.localizeNumber(this.itemTotalValue);
    const shipTotal = this.localizeNumber(this.shipTotalValue);
    const adjustmentTotal = this.localizeNumber(this.adjustmentTotalValue);

    for (const totalTarget of this.totalTargets) {
      totalTarget.innerText = total;
    }

    for (const subtotalTarget of this.subtotalTargets) {
      subtotalTarget.innerText = subtotal;
    }

    for (const itemTotalTarget of this.itemTotalTargets) {
      itemTotalTarget.innerText = itemTotal;
    }

    for (const shipTotalTarget of this.shipTotalTargets) {
      shipTotalTarget.innerText = shipTotal;
    }

    for (const adjustmentTotalTarget of this.adjustmentTotalTargets) {
      adjustmentTotalTarget.innerText = adjustmentTotal;
    }

    for (const adjustmentTarget of this.adjustmentTargets) {
      adjustmentTarget.hidden = (adjustmentTotal === "0");
    }
  }

  async connect () {
    const orderToken = this.token;
    const response = await this.spree.checkout.paymentMethods({ orderToken });

    if (response.isFail()) {
      this.handleFailure(response);
      return;
    }

    const cartResponse = await this.spree.cart.show({ orderToken });

    if (cartResponse.isFail()) {
      this.handleFailure(cartResponse);
      return;
    }

    const cart = cartResponse.success().data;
    const paymentMethods = response.success().data;
    this.paymentMethodsTarget.innerHTML = "";
    this.subtotalValue = cart.attributes.subtotal;
    this.itemTotalValue = cart.attributes.item_total;
    this.shipTotalValue = cart.attributes.ship_total;
    this.adjustmentTotalValue = cart.attributes.adjustment_total;
    this.totalValue = cart.attributes.total;
    this.currencyValue = cart.attributes.currency;

    for (const paymentMethod of paymentMethods) {
      const data = {
        id: `payment-method-${paymentMethod.id}`,
        value: paymentMethod.id,
        ...paymentMethod.attributes
      }

      this.paymentMethodsTarget.appendChild(this.applyTemplate(data, this.paymentMethodTemplateTarget));
    }
  }

  async pay(event) {
    event.preventDefault();
    event.stopPropagation();

    const orderToken = this.token;
    const date = new Date;
    const formData = new FormData(event.target);
    const payment_method_id = formData.get("payment_method_id");

    if (!payment_method_id) return;

    this.disableForm(event.target, true);

    // XXX: Currently SpreeClient expects us to send payment source
    // attributes as if it were a credit card.
    let response = await this.spree.checkout.orderUpdate(
      { orderToken },
      {
        order: {
          payments_attributes: [{ payment_method_id }],
        },
        payment_source: {
          [payment_method_id]: {
            name: "Pepitx",
            month: date.getMonth() + 1,
            year: date.getFullYear(),
          },
        },
      }
    );

    if (response.isFail()) {
      this.handleFailure(response);
      this.disableForm(event.target, false);
      return;
    }

    response = await this.spree.checkout.complete({ orderToken });

    if (response.isFail()) {
      this.handleFailure(response);
      this.disableForm(event.target, false);
      return;
    }

    let checkoutUrl = this.nextStepValue;
    const checkoutUrls = await this.spree.sutty.getCheckoutURL({ orderToken });

    if (checkoutUrls.data.length > 0) checkoutUrl = checkoutUrls.data[0];

    this.visit(checkoutUrl);
  }
}
